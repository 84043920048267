import { render, staticRenderFns } from "./AlertsList.vue?vue&type=template&id=b00941fa&scoped=true"
import script from "./AlertsList.vue?vue&type=script&lang=js"
export * from "./AlertsList.vue?vue&type=script&lang=js"
import style0 from "./AlertsList.vue?vue&type=style&index=0&id=b00941fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b00941fa",
  null
  
)

export default component.exports